import {currencyFormatCustom} from "../helpers";
import api from '../compenents/ApiController';
const config = window.configCrypt;

const BNB_TOKEN_BALANCE = '0x0000000000000000000000000000000000000000';

let web3Component;

const cache = [];

const connectButton = document.querySelector('.button-dropdown_wrapper.wallet-connect-btn');
const disconnect_button = document.querySelector('.wallet-disconnect');

window.onload = async () => {
  if (location.pathname.includes('/shop/')) return;
  web3Component = new window.web3Component(config);
  dropdown();
  const isConnected = window.web3Component.isConnected();
  if (!connectButton) return;
  if (!connectButton) return;
  if (isConnected) {
    await check_user();
    await auto_connect();
  }
  disconnect();
  await manual_connect();
};

const manual_connect = async () => {
  if (!connectButton) return;
  connectButton.addEventListener('click', async () => {
    const isConnected = window.web3Component.isConnected();
    if (isConnected) return;
    web3Component.connectWallet().then(async () => {
      await generateData();
      localStorage.setItem('user_id_bak', await get_user_id());
      disconnect();
    });
  });
}

const auto_connect = async () => {
  web3Component.connectWallet().then(async () => {
    await generateData();
  }).catch((e) => {
    console.error(e);
  })
}

const generateData  = async () => {
try {
  const wallet_sum = await web3Component.getTokenBalance(config.USDT_ADDRESS)
  console.log(wallet_sum);
  const {vid, bnb, locks} = await get_currency_data();
  console.log({vid, bnb, locks});
  cache.vid = vid;
  cache.bnb = bnb;
  cache.locks = locks;

  const currencies = generate_currency_array(vid, bnb, locks);
  cache.currencies = currencies;
  console.log(currencies);
  const wrapper = document.querySelector('.dropdown-wallet-connect-wrapper');
  wrapper.innerHTML = generateElement(currencies);
  modal_control(currencies);
} catch (e) {
  console.info(e);
}

  const wrapper = document.querySelector('.dropdown-wallet-connect-wrapper');
  const dropWrapper = document.querySelector('.custom-vidi-menu-dropdown');
  if (!dropWrapper) return;
  if (!wrapper) return;
  dropWrapper.classList.add('wallet-expanded');
  wrapper.classList.remove('hide');
  const disconnect = document.querySelector('.wallet-disconnect');
  if (disconnect) disconnect.classList.remove('hide');

  const status = connectButton.querySelector('.wallet-connection-status');

  if (status) {
    status.classList.remove('disconnected');
    status.classList.add('connected');
  }
}

const disconnect = () => {
  disconnect_button.addEventListener('click', async () => {
    const isConnected = window.web3Component.isConnected();
    if (!isConnected) return;
    const wrapper = document.querySelector('.dropdown-wallet-connect-wrapper');
    wrapper.classList.toggle('hide');
    await web3Component.disconnectWallet();
    localStorage.removeItem('walletconnect');
    disconnect_button.classList.add('hide');
    const status = connectButton.querySelector('.wallet-connection-status');

    if (status) {
      status.classList.remove('connected');
      status.classList.add('disconnected');
    }
    const dropWrapper = document.querySelector('.custom-vidi-menu-dropdown');
    if (dropWrapper) dropWrapper.classList.remove('wallet-expanded');
  })
}

let active = false;
const dropdown = () => {
  const trigger = document.querySelector('.custom-vidi-dropdown-trigger');
  if (!trigger) return;
  trigger.addEventListener('click', () => {
    const menu = document.querySelector('.custom-vidi-menu-dropdown');
    if (!menu) return;
    menu.classList.toggle('hide');
    if (!active) setTimeout(() => document.addEventListener('click',  outOfVidDropdown), 16);
    active = true;
  })
}

const outOfVidDropdown = (e) => {
  const _wrapper = document.querySelector('.custom-vidi-menu-dropdown');
  if (_wrapper && !_wrapper.classList.contains('hide')) {
    const pos = _wrapper.getBoundingClientRect();
    if ((e.pageX < pos.left) || (e.pageX > pos.right) ||(e.pageY > pos.bottom) || (e.pageY < pos.top)) {
      _wrapper.classList.add('hide');
      document.removeEventListener('click', outOfVidDropdown);
      active = false;
    }
  }
  setTimeout(() => document.removeEventListener('click', outOfVidDropdown), 16)
};


const modal_control = (currencies) => {
  const modal_placeholder = document.querySelector('.modal-wrapper');
  if (!modal_placeholder) return;
  modal_placeholder.innerHTML = modal_insert(currencies);
  show_modal();
  hide_modal();
  take_locks();
}

const take_locks = () => {
  const buttons = document.querySelectorAll('.take-button');
  console.log('buttons ', buttons);

  buttons.forEach(button => {
    button.addEventListener('click', async () => {
      const { lockType } = button.dataset;
      const { currencies } = cache;
      button.disabled = true;
      const {unlock_indexes, available} = currencies.find(cur => cur.type === lockType);

      let result;
      const promise = [];

      for (const lock_index of unlock_indexes) {
        promise.push(web3Component.withdrawLockedTokens(lock_index));
      }

      try {
          result = await Promise.all(promise);
      } catch (e) {
          return console.error(e);
      }

      if (result) {
        const modal_placeholder = document.querySelector('.modal-wrapper');
        if (!modal_placeholder) return;
        modal_placeholder.innerHTML += success_modal_insert(available);
        success_modal_close();
      }
      button.disabled = false;
    })
  })
}

const show_modal = () => {
  const modalTriggers = document.querySelectorAll('.open-vid-modal');
  [...modalTriggers].forEach(el => el.addEventListener('click', () => {
    const modal_wrapper = document.querySelector('.blur-portal__vid-info');
    if (modal_wrapper && !modal_wrapper.classList.contains('active')) {
      modal_wrapper.classList.add('active');
      hide_dropdown();
      setTimeout(() => modal_wrapper.classList.add('animate'), 16);
    }
  }));
}

const hide_modal = () => {
  const modal_wrapper = document.querySelector('.blur-portal__vid-info');
  const close_button = modal_wrapper.querySelector('.modal__close');
  if (close_button) close_button.addEventListener('click', () => {
    const modal_wrapper = document.querySelector('.blur-portal__vid-info');
    if (modal_wrapper && modal_wrapper.classList.contains('active')) {
      modal_wrapper.classList.remove('animate');
      setTimeout(() => modal_wrapper.classList.remove('active'), 300)
    }
  })
}

const success_modal_close = () => {
  const modal_wrapper = document.querySelector('.blur-portal__vid-success');
  const close_button = modal_wrapper.querySelector('.modal__close');
  if (close_button) close_button.addEventListener('click', () => {
    const modal_wrapper = document.querySelector('.blur-portal__vid-success');
    if (modal_wrapper && modal_wrapper.classList.contains('active')) {
      modal_wrapper.classList.remove('animate');
      setTimeout(() => modal_wrapper.classList.remove('active'), 300);
      setTimeout(() => location.reload(), 400);
    }
  })
}

const hide_dropdown = () => {
  const dropdown = document.querySelector('.wallet-expanded');
  dropdown.classList.add('hide');
}

const generate_currency_array = (vid, bnb, locks) => {
  locks.forEach(lock => lock.amount = +lock.amount);

  const private_locks = {
    data: locks.filter(lock => lock.amount === config.PRIVATE_AMOUNT)
  }

  const seed_locks = {
    data: locks.filter(lock => lock.amount === config.SEED_AMOUNT)
  }

  const other_locks = {
    data: locks.filter(lock => lock.amount !== config.PRIVATE_AMOUNT && lock.amount !== config.SEED_AMOUNT)
  }

  const private_data = private_locks.data;
  const seed_data = seed_locks.data;
  const other_data = other_locks.data;


  if (private_data.length > 0) {
    private_locks.raw_sum = sum_of_array(private_data, 'amount');
    private_locks.released = sum_of_array(private_data, 'released');
    private_locks.sum = private_locks.raw_sum - private_locks.released;
    private_locks.releasable_amount = sum_of_array(private_data, 'releasableAmount');
    private_locks.percent = private_locks.released / private_locks.sum * 100;
    private_locks.cliff = private_data[0].cliff / 60 / 60 + ' часов';
  }

  if (seed_data.length > 0) {
    seed_locks.raw_sum = sum_of_array(seed_data, 'amount');
    seed_locks.released = sum_of_array(seed_data, 'released');
    seed_locks.sum = seed_locks.raw_sum - seed_locks.released;
    seed_locks.releasable_amount = sum_of_array(seed_data, 'releasableAmount');
    seed_locks.percent = seed_locks.released / seed_locks.sum * 100;
    seed_locks.cliff = seed_data[0].cliff / 60 / 60 + ' часов';
  }

  if (other_data.length > 0) {
    other_locks.raw_sum = sum_of_array(other_data, 'amount');
    other_locks.released = sum_of_array(other_data, 'released');
    other_locks.sum = other_locks.raw_sum - other_locks.released;
    other_locks.releasable_amount = sum_of_array(other_data, 'releasableAmount');
    other_locks.percent = other_locks.released / other_locks.sum * 100;
    other_locks.cliff = other_data[0].cliff / 60 / 60 + ' часов';
  }

  const currencies = [{
    type: 'vid',
    headline: 'Баланс:',
    sum: vid + ' V',
  }, {
    type: 'usdt',
    headline: 'Баланс:',
    sum: bnb + ' USDT',
  }];

  if (private_locks.sum) {
    const {raw_sum, sum, releasable_amount, data, cliff} = private_locks
    currencies.push(
      {
        type: 'private',
        heading: 'Private',
        headline: 'Private',
        description: 'Заморожено',
        sum: currencyFormatCustom(sum, 'VID').replace('ID', ''),
        raw_sum,
        available: currencyFormatCustom(releasable_amount, 'VID').replace('ID', ''),
        raw_available: releasable_amount,
        indexes: data.map((el) => el.index),
        unlock_indexes: data.filter((el) => +el.releasableAmount > 0).map(el => el.index),
        data,
        cliff,
      }
    );
  }

  if (seed_locks.sum) {
    const {raw_sum, sum, releasable_amount, data, cliff} = seed_locks
    currencies.push(
      {
        type: 'seed',
        heading: 'Seed',
        headline: 'Seed',
        description: 'Заморожено',
        sum: currencyFormatCustom(sum, 'VID').replace('ID', ''),
        raw_sum,
        available: currencyFormatCustom(releasable_amount, 'VID').replace('ID', ''),
        raw_available: releasable_amount,
        indexes: data.map((el) => el.index),
        unlock_indexes: data.filter((el) => +el.releasableAmount > 0).map(el => el.index),
        data,
        cliff,
      }
    );
  }

  if (other_locks.sum) {
    const {raw_sum, sum, releasable_amount, data, cliff} = other_locks

    currencies.push(
        {
          type: 'other',
          heading: 'Другие',
          headline: 'Другие',
          description: 'Заморожено',
          sum: currencyFormatCustom(sum, 'VID').replace('ID', ''),
          raw_sum,
          available: currencyFormatCustom(releasable_amount, 'VID').replace('ID', ''),
          raw_available: releasable_amount,
          indexes: data.map((el) => el.index),
          unlock_indexes: data.filter((el) => +el.releasableAmount > 0).map(el => el.index),
          data,
          cliff,
        }
    );
  }
  return currencies;
}

const get_currency_data = async () => {
  const [vid, bnb, locks] = await Promise.all([web3Component.getTokenBalance(), web3Component.getTokenBalance(config.USDT_ADDRESS), web3Component.getUserLocks()])
  return {vid, bnb, locks}
};

const check_user = async () => {
  const user_id_bak = +localStorage.getItem('user_id_bak') || 0;
  const user_id = await get_user_id();

  if (+user_id_bak !== user_id && localStorage.hasOwnProperty('walletconnect')) {
    await web3Component.disconnectWallet();
    localStorage.removeItem('walletconnect')
    return true
  }
  return false;
}

const get_user_id = async () => {
  try {
    const request = await api.get({href: '/getUserBySession', body: {}})
    if (request.message) return console.error(request.message);
    return request.data;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

const sum_of_array = (array, el_name) => {
  return array.reduce((acc, el) => {
    acc += +el[el_name];
    return acc;
  }, 0);
}

const generateElement = (currencies) => currencies.map(el => `
        <div class="button-dropdown_wrapper d-flex justify-content-between">
            <div>
            <p class="body-15">${el.headline}</p>
            ${el.description ? `<p class="body-13 color-300">${el.description}</p>` : ''}
            </div>
            <div class="d-flex flex-row">
                <p class="body-15 me-2">${el.sum}</p>
                ${el.heading ? '<img src="/img/icon/question.svg" width="24" height="24" alt="question" class="open-vid-modal pointer">' : ''}
            </div>
        </div>
        `).join('');

const modal_insert = (currencies) => {
  return `
    <div
    class="blur-portal blur-portal__vid-info align-items-center justify-content-center">
    <div class="container">
        <div
            class="blur-modal blur-portal__email-activate col-6 d-flex align-self-center m-auto flex-column">
            <div class="modal__close d-flex align-items-center justify-content-center">
                <div class="icon__wrapper pointer">
                    <img src="/img/icon/close.svg" width="24" height="24" alt="Закрыть">
                </div>
            </div>
            <div class="modal-heading d-flex flex-row align-items-center">
                <p class="h2 mb-0 d-flex justify-content-center align-items-center">Баланс счёта</p>
            </div>
            <div class="spacer-32"></div>
            <div class="tariff-modal-body">
            ${currencies.map((el, index) => el.heading && `
            ${index > 0 ? '<div class="spacer-32"></div>' : ''}
            <p class="body-18-bold">${el.heading}</p>
            <div class="spacer-8"></div>
                <div class="tariff-detailed-row">
                    <div class="tariff-detail-name">
                        <div class="icon-bg-warning me-2">
                            <img src="/img/alert/warning.svg" width="24" height="24" alt="Внимание">
                        </div>
                        <p class="body-15">Заморожено</p>
                    </div>
                    <div class="horizontal-line"></div>
                    <p class="body-18">${el.sum}</p>
                </div>
                <div class="spacer-8"></div>
                <div class="tariff-detailed-row">
                    <div class="tariff-detail-name">
                        <div class="icon-bg-info me-2">
                            <img src="/img/alert/watch.svg" width="24" height="24" alt="Ожидайте">
                        </div>
                        <p class="body-15">Доступно</p>
                    </div>
                    <div class="horizontal-line"></div>
                    <p class="body-18">+ ${el.available}</p>
                </div>
                <div class="spacer-8"></div>
                ${el.raw_available > 0 ?
                `<div class="d-flex flex-row">
                    <div class="btn__default-primary-rounded take-button btn__fit pointer d-flex" data-lock-type="${el.type}">
                        <p class="btn__default-text body-15-bold">Take</p>
                    </div>
                </div>` : ''}
            </div>
            `).join('')}
        </div>
    </div>
</div>`
}

const success_modal_insert = (sum) => {
  return `
    <div
    class="blur-portal blur-portal__vid-success align-items-center justify-content-center active animate">
    <div class="container">
        <div
            class="blur-modal blur-portal__email-activate col-6 d-flex align-self-center m-auto flex-column">
            <div class="modal__close d-flex align-items-center justify-content-center">
                <div class="icon__wrapper pointer">
                    <img src="/img/icon/close.svg" width="24" height="24" alt="Закрыть">
                </div>
            </div>
            <div class="modal-heading d-flex flex-row align-items-center">
                <p class="h2 mb-0 d-flex justify-content-center align-items-center">Операция прошла успешно</p>
            </div>
            <div class="spacer-32"></div>
            <div class="tariff-modal-body">
                <div class="tariff-detailed-row">
                    <p class="body-15">Разблокировано</p>
                    <div class="horizontal-line"></div>
                    <p class="body-18">${sum}</p>
                </div>
            </div>
        </div>
    </div>
</div>`
}